import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accept-terms-popup',
  templateUrl: './accept-terms-popup.component.html',
  styleUrls: ['./accept-terms-popup.component.scss']
})
export class AcceptTermsPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
