import {isNullOrUndefined} from 'util';
export class NameSymbol {
  name: string;
  first: string;
  second: string;
  remaining: number;
  consonantRegex = /[qwrtzpsdfghjklyxcvbnm]/i;
  vowelRegex = /[aeiou]/i;

  constructor(name: string)
  {
    this.setName(name);
  }

  setName(newName: string) {
    this.name = newName;
    this.buildSymbol();
  }

  buildSymbol() {
    if (isNullOrUndefined(this.name) || this.name === '') {
      this.first = '';
      this.second = '';
      this.remaining = 0;
      console.log("empty");
      return;
    }

    const combining = /[\u0300-\u036F]/g;
    const name = this.name.normalize('NFKD').replace(combining, '').toLowerCase();

    const firstVowel = 'empty';
    const firstConsonant = 'empty';
    const secondVowel = 'empty';
    const secondConsonant = 'empty';

    const firstVowelMatch = this.getFirstVowelAfterIndex(name, 0);
    const firstConsonantMatch = this.getFirstConsonantAfterIndex(name, 0);

    let secondSyllableEnd;
    let nameRest;

    if (firstVowelMatch && firstConsonantMatch) {
      let syllableEnd;

      if (firstVowelMatch.index < firstConsonantMatch.index) {
        this.first = firstVowelMatch[0] + firstConsonantMatch[0];
        syllableEnd = firstConsonantMatch.index;
      } else {
        this.first = firstConsonantMatch[0] + firstVowelMatch[0];
        syllableEnd = firstVowelMatch.index;
      }

      nameRest = name.substr(syllableEnd + 1);

      const secondVowelMatch = this.getFirstVowelAfterIndex(nameRest, 0);
      const secondConsonantMatch = this.getFirstConsonantAfterIndex(nameRest, 0);

      if (secondVowelMatch && secondConsonantMatch) {

        if (secondVowelMatch.index < secondConsonantMatch.index) {
          this.second = secondVowelMatch[0] + secondConsonantMatch[0];
          secondSyllableEnd = secondConsonantMatch.index;
        } else {
          this.second = secondConsonantMatch[0] + secondVowelMatch[0];
          secondSyllableEnd = secondVowelMatch.index;
        }

        nameRest = nameRest.substr(secondSyllableEnd + 1);
      } else if (secondVowelMatch) {
        this.second = secondVowelMatch[0];
        nameRest = nameRest.substr(secondVowelMatch.index + 1);
      } else if (secondConsonantMatch) {
        this.second = secondConsonantMatch[0];
        nameRest = nameRest.substr(secondConsonantMatch.index + 1);
      }
    } else if (firstVowelMatch) {
      this.first = firstVowelMatch[0];
      this.second = 'empty';
      nameRest = name.substr(firstVowelMatch.index + 1);
    } else if (firstConsonantMatch) {
      this.first = firstConsonantMatch[0];
      this.second = 'empty';
      nameRest = name.substr(firstConsonantMatch.index + 1);
    }

    this.remaining = nameRest.length;

    if(this.remaining > 20) this.remaining = 20;

    /*
    if(firstConsonant) {
      secondConsonant = this.getFirstConsonantAfterIndex(name, firstConsonant.index + 1);
    }

    this.first =
    const firstConsonant = name.match(this.consonantRegex);
    const firstVowel = name.match(this.vowelRegex);
    let firstEnd = 0;

    if (firstVowel === null && firstConsonant === null)
    {
      console.log('Not enough characters.');
      return;
    }

    if (firstVowel && firstVowel.index === 0)
    {
      this.first = firstVowel[0];

      if (firstConsonant !== null) {
        this.first += firstConsonant[0];
        firstEnd = firstConsonant.index;
      }
      else {
        firstEnd = firstVowel.index;
      }
    }
    else if (firstConsonant && firstConsonant.index === 0)
    {
      this.first = firstConsonant[0];

      if (firstVowel !== null) {
        this.first += firstVowel[0];
        firstEnd = firstVowel.index;
      }
      else {
        firstEnd = firstConsonant.index;
      }
    }
    else {
      console.log('ERROR: ', firstConsonant, firstVowel);
    }

    if (this.name.length > firstEnd + 1)
    {
      const secondVowel = name.substr(firstEnd + 1).match(this.vowelRegex);

      if (secondVowel === null)
      {
        this.second = 'empty';
        this.remaining = name.length - firstEnd - 1;
      }
      else
      {
        const secondConsonant = name.substr(firstEnd + 1).match(this.consonantRegex);
        const secondStart = secondConsonant.index;

        if (secondConsonant === null)
        {
          this.second = secondVowel[0];
          this.remaining = name.length - firstEnd - 1;
        }
        else
        {
          let secondEnd;

          if (secondVowel.index > secondConsonant.index)
          {
            this.second = secondConsonant[0] + secondVowel[0];
            secondEnd = secondVowel.index;
          }
          else
          {
            this.second = secondVowel[0] + secondConsonant[0];
            secondEnd = secondConsonant.index;
          }

          this.remaining = name.substr(firstEnd + 1 + secondEnd + 1).length;
        }
      }
    }
    else
    {
      this.second = 'empty';
      this.remaining = 0;
    }
    */
  }

  getFirstVowelAfterIndex(name: string, index: number) {
    return name.substr(index).match(this.vowelRegex);
  }

  getFirstConsonantAfterIndex(name: string, index: number) {
    return name.substr(index).match(this.consonantRegex);
  }

  getFirst(): string {
    return this.first;
  }

  getSecond(): string {
    return this.second;
  }

  getRemaining(): number {
    return this.remaining;
  }

  getFirstConsonant(): string {
    if (this.first)
    {
      const matcher = this.first.match(this.consonantRegex);

      if (matcher) {
        return matcher[0];
      }

      return 'empty';
    }

    return '';
  }

  getFirstVowel(): string {
    if (this.first)
    {
      const matcher = this.first.match(this.vowelRegex);

      if (matcher) {
        return matcher[0];
      }

      return 'empty';
    }

    return '';
  }

  getSecondConsonant(): string {
    if (this.second === 'empty')
    {
      return 'empty';
    }
    if (this.second)
    {
      const matcher = this.second.match(this.consonantRegex);

      if (matcher) {
        return matcher[0];
      }
    }

    if(this.first)
    {
      return 'empty';
    }

    return '';
  }

  getSecondVowel(): string {
    if (this.second === 'empty')
    {
      return 'empty';
    }
    if (this.second)
    {
      const matcher = this.second.match(this.vowelRegex);

      if (matcher) {
        return matcher[0];
      }
    }

    if(this.first)
    {
      return 'empty';
    }

    return '';
  }

  getRemainingClass(): string {
    if(this.first)
    {
      return 'r' + this.remaining;
    }

    return '';
  }
}
