import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {NameSymbol} from './name-symbol';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-name-symbol',
  templateUrl: './name-symbol.component.html',
  styleUrls: ['./name-symbol.component.scss']
})
export class NameSymbolComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() bShowName: boolean = true;
  @Input() bShowBorder: boolean = false;
  @Input() size: string;
  symbol: NameSymbol;

  constructor() {
  }

  ngOnInit() {
    this.symbol = new NameSymbol(this.name);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(isNullOrUndefined(this.symbol)) return;

    for ( const propName in changes) {
      if (propName === 'name') {
        const change = changes[propName];
        this.name = change.currentValue;
        this.symbol.setName(this.name);
      }
    }
  }

}
