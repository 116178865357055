import {Component, Inject, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import {ModalService} from "../modal.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, NavigationEnd, Params, Router} from "@angular/router";
import { Location, DOCUMENT } from "@angular/common";
import {environment} from "../../environments/environment";
import {ScrollSpyService} from 'ngx-scrollspy';
import {PageScrollService} from 'ngx-page-scroll-core';

@Component({
  selector: 'app-header',
  animations: [
    trigger('flyInOut', [
      state('in', style({top: '0'})),
      transition('void => *', [
        style({top: '-200px'}),
        animate("500ms ease-in-out")
      ]),
      transition('* => void', [
        animate("500ms ease-in-out", style({top: '-200px'}))
      ])
    ]),
    trigger('flyInFromBottom', [
      state('in', style({bottom: '0'})),
      transition('void => *', [
        style({bottom: '-200px'}),
        animate("500ms ease-in-out")
      ]),
      transition('* => void', [
        animate("500ms ease-in-out", style({bottom: '-200px'}))
      ])
    ]),
    trigger('karmaIn', [
      state('in', style({opacity: 1})),
      transition('void => *', [
        style({opacity: 0}),
        animate("500ms ease-in-out")
      ]),
      transition('* => void', [
        animate("500ms ease-in-out", style({opacity: 0}))
      ])
    ])
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('loginModal', {static: false}) public loginModal: ModalDirective;
  preOrderButtonScrollOffset: number = 1000;
  karmaScrollOffset: number = 3300;
  gotFirstKarma: boolean = false;
  reservedKarma: number = 0;
  mobileKarmaBarOpen: boolean = true;
  showPreOrder: boolean = false;
  subscriptions = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private modalService: ModalService,
              private scrollSpy: ScrollSpyService,
              private changeDetection: ChangeDetectorRef,
              private pageScrollService: PageScrollService,
              @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
      // Automatic scroll
      this.subscriptions.push(this.route.fragment.subscribe(fragment => {
          if (fragment) {
            this.scrollToFragment(fragment);
          }
        }));
  }

  scrollToTop() {
    let element = document.getElementById('top');
    element.scrollIntoView({behavior: 'smooth'});
  }

  scrollToShop() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#merch',
      scrollOffset: 120
    });
  }

  scrollToDonation() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#donation',
      scrollOffset: 120
    });

  }

  scrollToKarma() {
    if(this.router.isActive("/", true))
    {
      this.pageScrollService.scroll({
        document: this.document,
        scrollTarget: '#how-to-get-karma',
        scrollOffset: 120
      });
    }
    else
    {
      this.router.navigate(["/karma"]);
      setTimeout(() => {
        window.scrollTo(0,0);
      });
    }
  }

  scrollToFragment(fragment: string) {
    if(fragment == 'shop')
    {
      setTimeout(() => {
        this.scrollToShop();
      });
    }
    else if(fragment == 'donation')
    {
      setTimeout(() => {
        this.scrollToDonation();
      });
    }
    else
    {
      const element = document.getElementById(fragment);

      if (element) {
        element.scrollIntoView({behavior: 'smooth'});

        // now account for fixed header
        const scrolledY = window.scrollY;

        if(scrolledY){
          setTimeout(() => {
            window.scroll(0, scrolledY - 120);
          });
        }
      };
    }
  }

  isOnKarmaPage() {
    return this.router.isActive("/karma", true);
  }

  getMooneyeUrl() {
    return environment.mooneyeUrl;
  }

  showModal(id: string) {
    this.modalService.showModal(id);
  }

  closeRewardHeader() {
    localStorage.removeItem('karmaCode');
  }

  showKarma() {
    return this.gotFirstKarma;
  }

}
