import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {ValidationService} from "../validation.service";

@Component({
  selector: 'app-control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent implements OnInit {
  errorMessage: string;
  @Input() control: FormControl;

  constructor() { }

  ngOnInit() {
  }

  getErrorMessage() {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }

    return null;
  }
}
