import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merch',
  templateUrl: './merch.component.html',
  styleUrls: ['./merch.component.scss']
})
export class MerchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    (function () {
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      if ((<any>window).ShopifyBuy) {
        if ((<any>window).ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
      function loadScript() {
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var client = (<any>window).ShopifyBuy.buildClient({
          domain: 'mooneye-store.myshopify.com',
          storefrontAccessToken: '626745cafd478bfc659df2ece3da9bf3',
        });
        (<any>window).ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '158411391079',
            node: document.getElementById('collection-component-1579527360397'),
            moneyFormat: '%E2%82%AC%7B%7Bamount%7D%7D',
            options: {
              "product": {
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "calc(25% - 20px)",
                      "margin-left": "20px",
                      "margin-bottom": "50px",
                      "width": "calc(25% - 20px)"
                    },
                    "img": {
                      "height": "calc(100% - 15px)",
                      "position": "absolute",
                      "left": "0",
                      "right": "0",
                      "top": "0"
                    },
                    "imgWrapper": {
                      "padding-top": "calc(75% + 15px)",
                      "position": "relative",
                      "height": "0"
                    },
                    "variant-selectors": {
                      "width": "auto",
                      "float": "left"
                    }
                  },
                  "title": {
                    "font-family": "Alegreya Sans, sans-serif",
                    "font-weight": "normal",
                    "color": "#ffffff"
                  },
                  "button": {
                    "font-family": "Alegreya Sans, sans-serif",
                    ":hover": {
                      "background-color": "#4fd685"
                    },
                    "background-color": "#58ee94",
                    ":focus": {
                      "background-color": "#4fd685"
                    },
                    "border-radius": "0px"
                  },
                  "price": {
                    "font-family": "Alegreya Sans, sans-serif",
                    "color": "#ffffff"
                  },
                  "compareAt": {
                    "font-family": "Alegreya Sans, sans-serif",
                    "color": "#ffffff"
                  },
                  "unitPrice": {
                    "font-family": "Alegreya Sans, sans-serif",
                    "color": "#ffffff"
                  }
                },
                "buttonDestination": "checkout",
                "text": {
                  "button": "Buy now"
                },
                "googleFonts": [
                  "Alegreya Sans"
                ]
              },
              "productSet": {
                "styles": {
                  "products": {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px"
                    }
                  }
                }
              },
              "modalProduct": {
                "contents": {
                  "img": false,
                  "imgWithCarousel": true,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px"
                    }
                  },
                  "button": {
                    "font-family": "Alegreya Sans, sans-serif",
                    ":hover": {
                      "background-color": "#4fd685"
                    },
                    "background-color": "#58ee94",
                    ":focus": {
                      "background-color": "#4fd685"
                    },
                    "border-radius": "0px"
                  }
                },
                "googleFonts": [
                  "Alegreya Sans"
                ],
                "text": {
                  "button": "Add to cart"
                }
              },
              "option": {
                "styles": {
                  "label": {
                    "font-family": "Alegreya Sans, sans-serif"
                  },
                  "select": {
                    "font-family": "Alegreya Sans, sans-serif"
                  }
                },
                "googleFonts": [
                  "Alegreya Sans"
                ]
              },
              "cart": {
                "styles": {
                  "button": {
                    "font-family": "Alegreya Sans, sans-serif",
                    ":hover": {
                      "background-color": "#4fd685"
                    },
                    "background-color": "#58ee94",
                    ":focus": {
                      "background-color": "#4fd685"
                    },
                    "border-radius": "0px"
                  }
                },
                "text": {
                  "total": "Subtotal",
                  "button": "Checkout"
                },
                "googleFonts": [
                  "Alegreya Sans"
                ]
              },
              "toggle": {
                "styles": {
                  "toggle": {
                    "font-family": "Alegreya Sans, sans-serif",
                    "background-color": "#58ee94",
                    ":hover": {
                      "background-color": "#4fd685"
                    },
                    ":focus": {
                      "background-color": "#4fd685"
                    }
                  }
                },
                "googleFonts": [
                  "Alegreya Sans"
                ]
              }
            },
          });
        });
      }
    })();
  }



}
