import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from "../../modal.service";

@Component({
  selector: 'app-basic-message-popup',
  templateUrl: './basic-message-popup.component.html',
  styleUrls: ['./basic-message-popup.component.scss']
})
export class BasicMessagePopupComponent implements OnInit {
  @Input() messages: string[];
  @Input() title: string;
  @Input() id: string;

  constructor(modalService: ModalService) { }

  ngOnInit() {
  }

}
