import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalService} from "../modal.service";
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @ViewChild('modal', {static: false}) modal: ModalDirective;

  @Input() title: string;
  @Input() public modalId: string;
  @Input() wide: boolean = false;

  @Output() popupClosed = new EventEmitter();

  private nextModal: string;

  constructor(protected modalService: ModalService) {  }

  ngOnInit() {
  }

  isModalActive() {
    return this.modalService.isModalActive(this.modalId);
  }

  hide(nextModal?: string) {
    this.nextModal = nextModal;
    this.modal.hide();
  }

  onHidden():void {
    this.modalService.hideModal();

    if(this.nextModal)
    {
      this.modalService.showModal(this.nextModal);
    }

    this.popupClosed.emit();
  }

}
