import { Component, OnInit } from '@angular/core';
import {NameSymbolComponent} from './name-symbol/name-symbol.component';
import {NameSymbol} from './name-symbol/name-symbol';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-name-generator',
  templateUrl: './name-generator.component.html',
  styleUrls: ['./name-generator.component.scss']
})
export class NameGeneratorComponent implements OnInit {
  name: string;
  currentSymbol: NameSymbol;
  bShowNames = true;
  bShowBorders = false;
  bShowGrid = false;
  bFixGrid = false;
  bShowExampleNames = false;
  fallenHeroes: string[];

  constructor(private activatedRoute: ActivatedRoute) {
    this.currentSymbol = new NameSymbol(this.name);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log(params);
    });

    this.fallenHeroes = [
      '@benscom',
      '[CG]hexwolf',
      'A. Doverspike',
      'Abhimanyu BIBRA',
      'Abrakawusel',
      'Abu_Rayan',
      'Adam Vazquez',
      'AdamY',
      'Addie',
      'Adora Belle',
      'Adrahoth',
      'Adrian Brettschneider',
      'Adrian Feiertag Esq.',
      'Adrian von Gaisberg',
      'Adrianne',
      'Adventurer Dusk',
      'Aegis',
      'Aidan Schattenfell',
      'Ailesdefae',
      'Aimee Lesley Sim',
      'AK',
      'Akaruime',
      'Akkarri',
      'Alain Grunig',
      'Albert Paschen',
      'alchemican',
      'Aleho',
      'Alex',
      'Alex J Sinclair',
      'Alex Padeletti',
      'Alex Tarrowhaven',
      'Alex Weerts',
      'Alexander "Wamillian" Rüby',
      'Alexander Grass',
      'Alexander Kjesa',
      'Alexandra Peters',
      'Alexia Walsh',
      'Alexis L',
      'Alfredo Boluso',
      'Alic Szecsei',
      'Alice Wren',
      'Alicia',
      'Alicia. O',
      'Alicja Sharwood',
      'Alina Löwe',
      'Alison Crystal Blazier',
      'Allen Crealy',
      'Alonso Bolarte',
      'Alxamei',
      'Amadak',
      'Amanda',
      'Amanda Normandin',
      'Amaterasu',
      'Amathindon',
      'Amon Bune The Greatest Of All Demons',
      'Amura-Of-Jupiter',
      'Amurous',
      'Amy Anne Scott',
      'Amy Hilliard',
      'Anders "Villanden" Olsen',
      'Andrew',
      'Andrew "Wulfson" Montgomery',
      'Andrew Fáelán Duke',
      'Andrew Roberts',
      'Andrew Shin',
      'Andrew Thivyanathan',
      'AndrewMcAndrew',
      'Andrzej Kubera',
      'Anduril',
      'Andy "VaultsOfExtoth" Wears',
      'Andy Harmeyer',
      'Andy Liaw',
      'Angelicus D"Mort',
      'AngryLupus',
      'Animyse',
      'Anja "Cha!" Schulz',
      'Anna and Megan Olson',
      'Anouk Potters',
      'AntiHeroInRedCosplay',
      'Antonia Delgado',
      'Anya - Paladin of Quilotasi',
      'Aoife',
      'Arashi of Ordo 3',
      'Archaea',
      'Archia',
      'Arden Chrusciel',
      'Aremir Grennwood',
      'Arikarka',
      'Arn-Eladur Thorondor',
      'Arphollik',
      'Artur "Ev1n" Plociennik',
      'Arty Cockerell',
      'Aru"tan Sipdrae',
      'Asameth',
      'Ash',
      'Ash Flekkrkin',
      'Ash O"Keefe',
      'Ash W',
      'Ashelynn Windwarrior',
      'Athan Mieczys?aw Alexander',
      'Atla Kan',
      'Atrany',
      'Atreidae',
      'Auchtahelweit',
      'Aurelius "Kardogan" Tolle',
      'Aureu',
      'Autumn Mayfield',
      'Avelera',
      'Avery Darastrix',
      'AviatingWolf',
      'Azzacura',
      'B Oshust',
      'Back To Basics Gaming',
      'Badger Dietzel',
      'Bailey',
      'Balereon the Wise',
      'Bandaid',
      'Banni Ibrahim',
      'Barbara Joanowitsch',
      'Barst Manson',
      'bearclaw',
      'Becca Fraser',
      'Beezadrella',
      'Begoña Castillo',
      'Benedikt Betz',
      'Benjamin Breiby',
      'Benjamin Ridge',
      'Beo Risfang',
      'Bernd Sedat Biesel',
      'Beshka',
      'Bettina S.',
      'Bhonas van Jonibho - Protector of the Silent and P',
      'Big Apple',
      'Bird the Goon Tamer',
      'Blade-Kartan',
      'Blaidd Llwyd',
      'Blake Moriguchi',
      'BloodTyphoon229',
      'Blue Gray',
      'Blue Wolf',
      'Bomwollen',
      'BooTricia',
      'Brad Thomas',
      'Bree Lee Bee',
      'Brenda Aisha Wolf',
      'Brennan Wohlers',
      'Brennan386',
      'Brent Voigt',
      'Bria A. Friestad',
      'Brian Vidosh',
      'BroadestOfSwords',
      'Buizma',
      'Byron McMullen',
      'Cabbit Skox',
      'Caeoltoiri',
      'CaistLP',
      'Caitlin Jane Hughes',
      'Cameron R. Powell',
      'CanisLupus',
      'Cari Marchetti',
      'Carmella Rigoli',
      'Carolina Jadwiga',
      'Caryn Oj',
      'Casgadom',
      'Cassandra "Autumn" Green',
      'Cathy Reinsel',
      'Catsinia',
      'CatStoleTheCrown',
      'Catya Culano',
      'Ceallach',
      'Cerneon',
      'CesCar',
      'Chaotic Cthulhu',
      'Cheklov',
      'Cheyriddle4',
      'Chi Huynh',
      'chiah hong chit',
      'Chief Tiberius Brody',
      'Chikarasu',
      'Chris (Alias) Jedzig',
      'Christian Middeldorf',
      'Christopher Frank Eckman',
      'Christopher Wood',
      'Chucky_0',
      'Chyzburger',
      'Cian',
      'Cinderdog',
      'Clemens Riemer',
      'Clemente Cuevas',
      'Cleo Monster',
      'Clint Andrew Hall',
      'Cody James',
      'Coene Anasah',
      'Coigreach',
      'Cole "The Machine" Zupan',
      'Concreteduck',
      'Connor Hinkelmanns',
      'Connor James Therrien',
      'Convexity',
      'Coralyn Swift',
      'Cosmic_Snail92',
      'Craig Simmons',
      'Creedora',
      'Creeperlasse und Emohonk',
      'Crow of Starlight',
      'Crowhopper',
      'Cryserenity',
      'C\'rysta Zeith of the Eorzean Coeurl tribe.',
      'Cryx',
      'Cyb',
      'cyberspace-exile',
      'Cynthia Ongena',
      'cyRex Wolf',
      'Cyrisma',
      'd4n0m4ly',
      'daedalia',
      'Dagmantis',
      'DALETO',
      'Daljit Chandi',
      'Dandelion',
      'Dane Hulett',
      'D"Ange R. Noodle',
      'Dani',
      'Daniel',
      'Daniel "bubserle" S.',
      'Daniel Buhrman',
      'Daniel DeArmond',
      'Daniel Marx',
      'Daniel Mason',
      'Daniel O"Neill',
      'Daniel Otterbach',
      'Daniel Schäfer',
      'Daniel Tekieli',
      'Daniel the Seeker',
      'Danni Alnazar',
      'Danny Mol',
      'Dark Warrior 85',
      'DarkSiide',
      'DarthPoo',
      'Database404',
      'David "The Dagger" Ruffing',
      'David Cambero Peralta',
      'David Nulty',
      'Deanna McArthur',
      'Deanna Phillips',
      'deathmule',
      'deleted',
      'Demoncat',
      'Demonfoxgaming',
      'Denis Wrobel',
      'Denise "Skye" Hughett',
      'Dennis Pehlke',
      'Der mit dem Gronkh Tanzt',
      'DeRaaph',
      'Destiny Gonet',
      'Destiny Ray Judd',
      'Detlef Wergin',
      'Devolver Digital',
      'Devon Blunk aka Zullks',
      'Deyo',
      'Dial the Dauntless',
      'Diana Lennox',
      'Diane Abbondante',
      'digith',
      'Dirk Andersen',
      'DJ',
      'dnapolymerase',
      'Dokter Jampot',
      'DoNotMindMe',
      'Dorothy Wolfje',
      'DR',
      'DragonEnchantress',
      'DrahcirAloer',
      'Drakaina',
      'Drake Rennick',
      'Dramavian',
      'DreamedReality',
      'Dresheyl VanDragonheart',
      'Drizzle',
      'DuckInADungeon',
      'Duckwraith',
      'dumbpad the gay',
      'Ed "Xangbar" Hopper',
      'Edna DiscoKittii Rouse',
      'Eeka',
      'Egari',
      'Egarl Silverhowl',
      'Eleanzhar',
      'Eliste',
      'EliteDingo',
      'Elizabeth Anne Lyons',
      'Elizabeth Fuenzalida ',
      'Elizabeth Mendes',
      'Elizanna Cyn',
      'Ellen O" Dale',
      'Elyk Eromlliw',
      'Emily Artimez',
      'Emily Osburn',
      'Emily S',
      'Emma Jones',
      'Emmie Fish',
      'Epsilonic',
      'Eric Monzon',
      'Erik "Gronkh" Range',
      'Erik Sundqvist',
      'Erika',
      'Erin A Sayers',
      'Erlend Aadnøy',
      'Erwin de Jong',
      'Esther Rivera',
      'Eu Fuchs',
      'EverDawn',
      'EveyIX',
      'Evi Snowmew',
      'Exkleriarch',
      'F.A. Radityatama',
      'fabelayTV',
      'Fabian P.',
      'Fabio Kratzmaier',
      'Faelyn Cypher',
      'FallenNemesis',
      'Fauxus',
      'Fay Steele',
      'Fearghasmor Macullum',
      'Felix the Fox',
      'Fellbobbl (Larissa H.)',
      'Fellow Traveller',
      'FemmeFox',
      'FenjaWolfheart',
      'Fenris',
      'Fenryal Lunae',
      'Ferdverel',
      'Feuerblatt',
      'fiarce',
      'FierySnow',
      'figureouturself',
      'Findalfen Mitjroch',
      'Finnegan Romy',
      'First Sergeant Spicer',
      'FlameEnvy',
      'Florian Haase',
      'FlorianWue',
      'Floris HB Overvelde',
      'fluffail',
      'Foefan',
      'Fox Galewarden',
      'FoxViVi',
      'FragToyd',
      'Frances McGregor',
      'Franck Martin',
      'franckv',
      'Frank',
      'Frank Branh',
      'Frank K. Reichel',
      'Frank Turner',
      'Fraser the Wanderer',
      'Freddy',
      'Frederico "Ittosai" Jabulka',
      'Frederik Lauridsen',
      'Frederique Noel',
      'Freon41',
      'Fribbtastic',
      'Fridey',
      'FriedKilamari',
      'FriendOfMonkeys',
      'Fujiiro',
      'Funs1z3',
      'Furever',
      'Furever Andadae',
      'Fynikoto Jacobus',
      'G4B__SpaWn',
      'Gürkchen',
      'Gabriel Victus',
      'Gaelle Muavaka',
      'Gaia',
      'Gailend',
      'Galythia',
      'Gamefreak106',
      'GamerChic_ken',
      'Garret Firl',
      'genesiel',
      'Genevieve Matte',
      'Gin',
      'Goatframe',
      'Goatmaster9338',
      'Goddard Wolf',
      'gory',
      'Grädel Roman',
      'Graham the Grandmaster',
      'Grant Boscamp',
      'Graxia',
      'Griever_Zero',
      'Grimm',
      'Grisnir',
      'Guephren',
      'Hana Younes',
      'Hanna Ausborn',
      'Hannes Toedter',
      'Hans Petersen',
      'Harnas',
      'Harry Mauger: Proud Surveyor and Warrior',
      'Heartlessyin',
      'Heather',
      'Heather & Erren Hayes',
      'Heidi of the Lake',
      'Hendrik Rump Froggy & his Team',
      'Henna Cottontail',
      'Henrik Jasionowski',
      'HesitantKarma',
      'Hime Moon',
      'hnpkfa',
      'HollowedWyld',
      'Holly Hunt',
      'hom_sin_nom',
      'Honeykeks',
      'Hope Carranza',
      'Hossy55',
      'Hrafnkarl',
      'Huebmayer',
      'HungryDemon',
      'IllustriousT',
      'Inareon',
      'Ingrid Hoehl',
      'Inkydoo',
      'Inochii',
      'Inuma Fizzlethub',
      'Inventropy',
      'Iralis Phar',
      'Irina Gepart',
      'Ironwing Icarus',
      'isalyk',
      'Ismael Santiago',
      'ItsJustLynn',
      'Iva',
      'Iztok',
      'J.E.D.1',
      'Jônatas Lima Póvoas',
      'Jackson "Crowbird" LaDuke',
      'Jacob Clemons',
      'Jada',
      'JaffaArchfiend',
      'Jake Hunnicutt',
      'Jake the Snake',
      'James (Pykie666) Loughran',
      'James Gow',
      'James Hogan',
      'Jamie Black',
      'Jamie Law',
      'Jane Shi',
      'Jasmin Manck-Götzenberger',
      'Jason Duke',
      'Jason Mesnard',
      'Jason Moon',
      'Jasper',
      'Jaws of Sidonia and Liz Morales',
      'Jaywalkings',
      'Jazawe',
      'Jazcek Braden',
      'Jean Smetz',
      'Jeff Fisher',
      'Jeffalius Bowstar',
      'Jeffrey Swofford',
      'Jens Demmelmaier',
      'Jeremy Paul Barrett Glatz',
      'Jerrick Le',
      'Jerry Farley',
      'Jesentra',
      'Jess Turner',
      'Jesse Swenk',
      'Jessica "White Wolf" Ferrarella',
      'Jessica Albery',
      'Jessica Fidi',
      'Jezail Phrix',
      'Jmartoine',
      'Joanna Wierzbicki',
      'Johan Draaisma',
      'John "CursedTechnocrat" Bejarana',
      'John <3 Maria',
      'John De Young',
      'John Elliott',
      'John Hoilman',
      'Johnathan W.',
      'Jojo',
      'Jonathan Andrews',
      'Jonathan McIlvenny',
      'Jordan Aguirre',
      'Jordan Danielle Schneider',
      'Jordan Smith',
      'Jose Cardoso',
      'Josef Janenz',
      'Josef Neumaier',
      'Joseph Abrera',
      'Joseph Elyas',
      'Joshua Jacobson',
      'JSify',
      'juggie',
      'julemand101',
      'Juli & Svenja',
      'Julia Lana Lark',
      'Julie Christensen',
      'Julie Gilson',
      'Julie York',
      'Julien A Féraud',
      'Jurafalle',
      'Just.Vantastic',
      'Justina Petry',
      'Justus Brinckmann',
      'Jutta Wirthmüller',
      'Jyron',
      'Jytra',
      'Kabc',
      'Kado the Penguin Knight',
      'Kae Winters',
      'Kaelgren',
      'Kage',
      'Kahlar of Watauga Clan',
      'Kalle Lehkonen',
      'Kalle MacNiven',
      'Kamui',
      'Karebian',
      'Kari Peters',
      'Karl Wing',
      'Karle Frazier',
      'Karsten Buhlrich',
      'Karsten FW',
      'Kassandra Salas',
      'Kat Purdy',
      'Kate Fox',
      'Kathryn McLennan',
      'Katt of Rivercrest',
      'Katze Meow',
      'Kay Elspas',
      'Kayjay late mole',
      'Kayru',
      'Kaz',
      'Kaz The Unenlightened',
      'Kcurai',
      'Keet Fox',
      'Keith Reid MacArthur',
      'Kelly Dulcie',
      'Kerr D\'Ercole',
      'Kevin Ethridge',
      'Kevin Mrotek',
      'Kevonni',
      'KFu',
      'Khy Louis P. B.',
      'Kimberly Whiskey Finch',
      'King Isaac',
      'Kinsey Hufnagle',
      'Kira Callinox',
      'Kira the Flame Hearted',
      'KiraraCutie',
      'Kirbie Dramdahl',
      'Kirrah',
      'Kisa Kitsugi',
      'KissMeKarma',
      'Kit Fox',
      'Kitsudragon',
      'Kitsuki',
      'Kitsune Tarix',
      'Kittykat',
      'Kjersti Swenson',
      'Klubia',
      'Knight Erock',
      'Knuddelbock',
      'Kobold+Shuft',
      'Koga - A Best Friend',
      'Kogath',
      'Kokoronotomo',
      'Komino9',
      'Konro BananaMan',
      'Korami',
      'Kori Zimmer',
      'Koriii',
      'Koroyabi',
      'Koslo',
      'Kralle von Klauenfels',
      'Kreature',
      'Kristin Taggart',
      'Kristina Zimmermann',
      'Kristine Turner',
      'Krjal',
      'Krolshi',
      'Kronaxx',
      'KsaRedFx',
      'Kurt Blanco',
      'Kyamay',
      'Kyle Walsh',
      'Kyoka',
      'Kyru Hikari',
      'Kytte',
      'Lönazzz',
      'Lady Asvoria',
      'Lady Draon',
      'Lando',
      'Lane D\'Alessandro',
      'Larus',
      'Laura Dunmore',
      'Laura Freude (Levollia)',
      'Lauren F. Ignacio',
      'Lauren Fickinger',
      'Laurette van Ham',
      'Lavoxan',
      'Leanne (Lan) Lane',
      'Leanne Froc',
      'Leigh Lord',
      'Lennart Bohm',
      'Leo Wolf Monti',
      'Leon Hitenmuri',
      'Leon Najarian',
      'Leonard Grolleman',
      'Leporidae Fluff',
      'Leslie L',
      'Lexy van D',
      'Libalite',
      'Lili',
      'Lilith Wolfe',
      'Lilli-eun Robinson',
      'Lilu Vakarian',
      'Linko Butyrum',
      'linkxk64',
      'Linus Cresfallen',
      'LiquidAg',
      'Lisa "Puppets" Bober',
      'Lisa Fidi',
      'Lisa Joy Black',
      'Lisa Marie Liebich',
      'LittleGhostFox',
      'Liu Hongyou',
      'lochness',
      'Loki Aldeno',
      'Loong Dong Yang',
      'LOPAP',
      'L\'orelei Sekhmet',
      'Loubuca',
      'Luca R',
      'Lucas T.',
      'Luci Fleur',
      'Luciano C.',
      'Lucie Ayala',
      'Lucien shepherd',
      'Lucryllyn',
      'Luis "LuiBert" Fritzsche',
      'Luisagna Emrys Holmes',
      'Lukas "Kaji" Dittrich',
      'Luke Cardellino',
      'Luke Wilde',
      'Luna',
      'Lupa517',
      'Lupus Silvae',
      'Lydia "Wolfheart" Langston',
      'Lyk0s15',
      'Lykara',
      'Lynx',
      'Mad Rook and Mad Gryphon',
      'Mada',
      'Madison W.',
      'MagicalTux',
      'Maid Marin',
      'Mandy Mouse',
      'ManedCalico',
      'Manga0205',
      'Manh Huy "Hiro" Tran',
      'ManuZ',
      'Marc Probst',
      'Marc Schläpfer',
      'Marcel "dino" Rogge',
      'Marcel Thielitz',
      'Marco & Polyna',
      'Marco Konar',
      'Marc-Trajan Caton',
      'Marcus',
      'Marcus "SnowFox111"',
      'Margaux Novak',
      'Maria Frantz',
      'Mariandré',
      'Marie Hanerkam',
      'Marie R.Draco',
      'Marina',
      'Mario Rossignoli',
      'Marion Tanaka',
      'Marisa Beske',
      'Marius the Mad',
      'Mark "Bean" Kuchler',
      'Mark Conrad',
      'Markus "Tocker" Flesch',
      'Markus Bäsecke',
      'Markus Direwolf',
      'MarkW',
      'Martin Miggs',
      'Marx Jason',
      'Maryanne_666',
      'Marzolan Terrigath',
      'Mathias Silvers',
      'Matlock Hargrove',
      'Matrix',
      'Matt "Hawkini" Hawkins',
      'Matt Robinson',
      'Matteo Domeniconi',
      'Mattertron',
      'Matthew "J Wall" Wallace',
      'Matthew Taylor A',
      'Matthew Thibault',
      'Matthias "pumpedkicks" Nossel',
      'Mayo',
      'Mea Tiihonen',
      'Mearevia',
      'Mechafox',
      'Medrus',
      'Meg Simons and Kevin Weaver',
      'Megan Davey',
      'Megathan James Brown Esquire The Third',
      'Melanie Lane',
      'Melina Norfolk',
      'Melissa D',
      'meNace',
      'Menschenbrecher',
      'Mercy Weathers',
      'Merrick Taloryn',
      'Mias',
      'Michael',
      'Michael Cobb',
      'Michael Drewblé',
      'Michael L Miller',
      'Michael Pietrelli',
      'Michael Tewiele',
      'Michael Vaiceliunas',
      'Michael Winay',
      'Michelle Thompson',
      'Mickaël "Nibi" Thery',
      'Midnight Daxter Summers',
      'Midnight Rein',
      'Mieke2303',
      'Miez',
      'Mija Kennedy',
      'Mika Stalzer',
      'Mike Burke',
      'Mike Flesher',
      'Mikkel Kjær Jørgensen',
      'Mimiru',
      'Min the Dead DM',
      'Mindel',
      'MiniFluffnKaiju',
      'Minn Edenflower',
      'Miraculas',
      'Miriam',
      'Miroslav Hofer',
      'Miroslav Hofer Jr.',
      'mizore88',
      'Mocustos',
      'Momoless',
      'Monica Leonard',
      'Moredir',
      'Morgan',
      'Morgan Wolf',
      'Mr. Toots',
      'n4chtv0gt',
      'N7Lisa',
      'Naaan',
      'Nabiryi',
      'Nadineko',
      'Nairoty',
      'Nakara',
      'Nanonium',
      'Naoma Hiru',
      'Nashoba Sievera',
      'Nathaniel Koolstra',
      'Nauru Lionne',
      'Nefrarya',
      'NEKODAFTW',
      'Nemo Griffith',
      'Nephel',
      'Neptune Thane',
      'netshaman',
      'NeverSated',
      'Nevia Dark',
      'Nicholas Edwin Leininger',
      'Nick Mariën',
      'Nico "The Chosen One" Kolstee',
      'Nicolas Gaumer',
      'Nicole H.',
      'Nicole pfau',
      'Niels "TraubenGod" Schwerdten',
      'Nigel Machin',
      'Nightbird(fr)',
      'Nightfire',
      'Nightshade De Mer',
      'Nikia Traumschatten',
      'Nikita Hetherington',
      'Niko Rauner',
      'Niko Zintz',
      'Nimae',
      'Nisura Takanae',
      'Nocturne Flamme',
      'Nolan Nargang',
      'Nora Hailey',
      'Noran Winterwolf',
      'Nordikdude',
      'Nosgoth4life',
      'Nox Genussgift',
      'Nox-Wolf',
      'Number-1-Suspect',
      'Nurrd',
      'Nyactis',
      'Nyeeen',
      'nyoo',
      'Ohad Kanne',
      'Oliver Mackey',
      'Oliver Rennie',
      'OMGTheMuffinMan',
      'Oni Kudaki',
      'Onion Monkey',
      'Opal Orchid',
      'Orion Wolfie',
      'Oscar Keller-Johnson',
      'Oscar Manjarrez',
      'PacsMan92',
      'Pady333',
      'Paige Welton',
      'Pangur Bán',
      'Parallax',
      'Patrick Schmurr',
      'Pattarchus & Fenrikur',
      'Paul Shin',
      'Pedrakahn',
      'Pedro SP Huot',
      'Pepper Rose',
      'Per Kristian Brastad',
      'Perzephone',
      'Peter Bergler',
      'Peter Jackson',
      'Phanstar0',
      'Philipp Kathage',
      'Phoenix',
      'Pia',
      'Pia Hanker',
      'Pierre Heler-Caruel',
      'Pikachamp1',
      'PinothyJ',
      'Piro_Maniak',
      'PK Goober',
      'Ponah',
      'Poppy Rippy - Two Great Dogs',
      'Priesi',
      'Princess Luna',
      'propellerhead',
      'Prussiakat',
      'Purple justice',
      'qawas',
      'Qiu',
      'Quenossy',
      'Quinne',
      'R.S. Thaddeus',
      'Rabiesgirl',
      'Rachael Barry',
      'Rachel Campbell',
      'Rachel Fry',
      'Rahel Jader',
      'Raidrier',
      'Rainbow Dash',
      'RainbowFox',
      'Rakan',
      'Randy Zhang',
      'Rarrum',
      'Ravaris',
      'Raven Blackwolf',
      'Ravy Vanoh',
      'Raya die Weise',
      'Razlik Corgan',
      'Rebecca Beimers',
      'Rebecca Brown',
      'Rebecca Mößmer',
      'RehKal Murloq',
      'Rena',
      'Rene Guo',
      'ReptilianSamurai',
      'Richard',
      'Ricky Holen',
      'RoadRunner',
      'Rob H',
      'Robert B Walden IV',
      'Robert D. Stewart',
      'Robert Kinns',
      'Robert Scripps',
      'Robin Christopher Ladiges',
      'Robin Gitliz-Schwab',
      'Robnosh',
      'Ronja Hirth',
      'Roogz GP',
      'Roran Blaze',
      'Rose Joynt',
      'Rose J.',
      'Rosemary Hall',
      'Ross Williams',
      'Roy',
      'Runa',
      'RuneFiye',
      'Rutana',
      'Ryan',
      'Ryan Edmiston',
      'Ryan Ludington',
      'Rylorien Luna',
      'Rythen Eugea',
      'S0ulDrag0n',
      'Søren J. Jasper',
      'Sabina',
      'Sabine Balfanz',
      'Sable Gryphon',
      'Saeil',
      'Saidakvil',
      'Sairah',
      'Salien',
      'Sam Bryant',
      'Samantha Cocoris',
      'Samantha Rachelle Eppler',
      'Sami M.',
      'Sandra Schmid',
      'Sarah "DaPanda" Doll',
      'Sarah "Dusk" Massey',
      'Sausage of justice',
      'Scarlet Ivie',
      'Scarlett Vithica',
      'Schingiuire',
      'Schmierwoschd',
      'Scompey',
      'Scott Born',
      'ScratchCo',
      'Screwball',
      'Scribe Skye',
      'ScruffyClasher',
      'Sean Harry',
      'Sebastian Komuth',
      'Seiji Brightscale',
      'Serena The Great',
      'Serge "SilverFolfy" F.',
      'Seth2285',
      'Shadow Cybros',
      'Shadow Gardevoir',
      'Shadow Pryo',
      'Shadowmanji',
      'ShakerGER',
      'Shana ShadowWing',
      'Shandini',
      'Shane Michael Holtz (Dragon)',
      'Shaney Mac"s Rovers',
      'Shanie Tanya',
      'Shanna Legg',
      'Sharatur',
      'Shawn Kutschker',
      'shawurai',
      'Shee-Chwan',
      'Shelley Hayes',
      'Shiaru',
      'Shira Frostpaw',
      'ShlomoMZHH',
      'ShokiNN',
      'Shredded Poppy',
      'Sijoma4u',
      'Silje Skjønsberg',
      'Silje Skjønsberg',
      'Silve Slade',
      'Silver ShadowLight Wolf',
      'SilverDaimon',
      'Simon (Villspor)',
      'SiNC',
      'Sinnamon',
      'Siona DeSantis',
      'Sioned Derrington',
      'Sipka Gábor',
      'Sir Sandwich Spread VII',
      'Sirhc Noregreb',
      'Sithu WT',
      'SketchKit',
      'Sky Leia',
      'Skygirl',
      'Skyler Phoenix',
      'SlamDuncan',
      'SleeppingWolf',
      'Smegol',
      'Snowarcher',
      'Soichiro Sunagawa',
      'Someur',
      'Sonja Martin-Elson',
      'Sophie Louise Burton',
      'Sophie Pedriks',
      'souljack',
      'Spug',
      'Stacy Gipson',
      'Steel Town Erick',
      'Stefan Di Betta',
      'Stefan Lewantoski',
      'Steph Charles',
      'Stephanie LeBouthillier',
      'Sterling Wild Pigeon Treadwell',
      'Steve Escalante',
      'Steve MacFarlane',
      'Steven "GiverOfSleep™ Walker Esq.',
      'Stormy',
      'Strepimassix',
      'Stuart Turnbull',
      'Su-Li Chin',
      'Suminya the Mighty Neko',
      'Sune Yoko',
      'SupaSpark',
      'Superbabou77',
      'SuperZanti',
      'Surallis',
      'Sweety Thomas-Wheeler',
      'Swevenfox',
      'Sydney "Scare966" Russell',
      'Syleira',
      'Tâ€”oâ€”râ€”Y',
      'Tairen Cifer',
      'Talia',
      'Tamir K.',
      'Tammy Hunter',
      'Tan Jie Xin',
      'Tandrox',
      'Tara Thayer',
      'Tarn33',
      'Tasha Rosenbaum de Lotero',
      'tatertea6',
      'Tatjewed',
      'Taylor Fielding',
      'Tcul',
      'TemacorT',
      'Tevin Hicks',
      'Teyl',
      'Thane Armbruster',
      'The Always Lost',
      'The Pauline Index',
      'The Wolfpup',
      'the1337grunty',
      'theawfulmrbird',
      'TheCryingSun',
      'THOMAS SYLVAIN',
      'Tian Flegg',
      'TibialOrchid1',
      'Tierra Sanchez',
      'Tina Heitmann',
      'Tine Silíní',
      'Tiril Pollard',
      'Tisalovelybug',
      'TitanSquash',
      'Tiuku Koukkuhäntä Tonttu',
      'Tobias Kurth (AKtobi47)',
      'Toby Collins',
      'Toby Ridsdale',
      'Tomer Bar-Shlomo',
      'Tompkins Family',
      'Tony Contreras',
      'Tor Shen El Ta"aishi',
      'Toraki74',
      'Tori Manning',
      'Tori Stukins',
      'Torielle Schalna',
      'Toroka',
      'Toronia',
      'Torudo',
      'Tresca',
      'Treve the Valiant',
      'Trisha Kirk',
      'Tristevoix',
      'Triton the Lavendeer ',
      'trsthen',
      'Truelle',
      'TruePup',
      'Tscherri',
      'Two-Tails',
      'Tyberius "Ty-Ty" Damian Privott',
      'Tyler Anthony Edwards',
      'Tyler Criswell',
      'Tyler Gene McDermott',
      'Tyler Jewiss',
      'Tyrrlin Flamestrike',
      'Uisce',
      'Ulkesh',
      'Ulysses G. Banuelos',
      'UnafraidBeaver',
      'Uriah H. Brown',
      'Vahlian',
      'Valerion',
      'ValheruWolf',
      'Vascalunav',
      'Vasili Hrebinka',
      'Vegard Sandanger Hauso',
      'Veijlara',
      'Ven',
      'Vesko Gavrilov',
      'Vesper Sovereign',
      'Vi',
      'Vima',
      'Vitani',
      'Vitarka',
      'VladOmancien',
      'Voldis Magnus',
      'Volker "Zarkos" Tiedemann',
      'Vrak',
      'Vrghr Dragonwolf',
      'Vroni Wolfstochter',
      'Vulli',
      'Walter Korman',
      'Wastrel',
      'Waterfox',
      'Wee Heavy',
      'Weisszahn Trampeltatz',
      'Weylin Kuro',
      'whitewolfnz',
      'Whyaylooh',
      'Wildnate',
      'William Dite',
      'William Robertson',
      'William Taylor Lashbrook',
      'William Trujillo Zimmerman',
      'Willowallo',
      'Willy "Sunset Hero" B.',
      'Wing Hulgiya',
      'Wirdjos',
      'Wolf',
      'wolf1438',
      'WolfCommander40K',
      'Wolfdenden',
      'Wolfren',
      'Wolfstar444',
      'WONG CHONG SHENG',
      'Wraith of Seth',
      'Wuffl',
      'Wuji the Cat',
      'Wulfrún Dracanmódor',
      'x Perinoid x',
      'Xajoris',
      'Xangbar',
      'Xavier Warchol',
      'Xeiana',
      'Xelvanas',
      'Xheon',
      'xXilluminatXxHD',
      'Yargleflarg',
      'Yasmin Matthews',
      'Yu Okada',
      'Yuuichi89',
      'Yuuryuu',
      'Zach Cogan',
      'Zachary H. Guss',
      'Zander Saurondon',
      'Zaph',
      'Zephon the Hidden',
      'Zephur Grimm',
      'Zinnthra Starbrook',
      'Zuckerengelchen',
      'Zuri'
    ];
  }

  ngOnInit() {
  }

  nameUpdated(event: any) {
    this.currentSymbol.setName(this.name);
  }

  clearName() {
    this.name = '';
    this.currentSymbol.setName(this.name);
  }
}
