import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Location, PopStateEvent} from "@angular/common";
import {FacebookService, InitParams} from "ngx-facebook";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isLoading: boolean = false;
  private lastPoppedUrl: string;

  constructor(public router: Router, private location: Location, private fb: FacebookService) {
    let initParams: InitParams = {
      appId: '475617919453305',
      xfbml: true,
      version: 'v2.9'
    };

    fb.init(initParams);
  }

  ngOnInit() {
    this.location.subscribe((ev:PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        // if (ev.url == this.lastPoppedUrl || this.lastPoppedUrl == "" && ev.url == "/") {
        //   this.lastPoppedUrl = undefined;
        // } else {
        //   window.scrollTo(0, 0);
        // }
      }
    });
  }
}
