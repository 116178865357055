import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-share-buttons-container',
  templateUrl: './share-buttons-container.component.html',
  styleUrls: ['./share-buttons-container.component.scss']
})
export class ShareButtonsContainerComponent implements OnInit {
  @Input() url: string;
  @Input() title: string;
  @Input() image: string;
  @Input() desc: string;
  @Input() tags: string;
  @Input() tweet: string;

  constructor() { }

  ngOnInit() {
  }

}
