import {Component, OnInit, AfterContentInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../validation.service";
import {environment} from "../../environments/environment";
import {Http, Headers} from "@angular/http";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentInit {
  form: FormGroup;
  loading: boolean = false;
  error: string;
  success: string;

  constructor(private formBuilder: FormBuilder, private http: Http) {
    this.form = this.formBuilder.group({
                                         email: ['', [Validators.required, ValidationService.emailValidator]]
                                       });
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
  }

  onSignUp() {
    if(this.form.valid)
    {
      this.loading = true;

      let postData = {
        email: this.form.value['email']
      };

      return this.http.post(environment.apiUrl + 'newsletter/', postData, {headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'})})
                 .map(res => res.json())
                 .subscribe(
                   data => {
                     this.loading = false;
                     let body = JSON.parse(data.content.body);

                     console.log(body);
                     if(body.status === 'subscribed')
                     {
                       this.form.reset();
                       this.success = data.content.updated ? 'You are already subscribed to our newsletter. We have updated your profile information.' : 'You have successfully subscribed to the Lost Ember Newsletter!';
                       this.error = '';
                     }
                     else
                     {
                       this.success = '';

                       if(body.title === 'Member Exists')
                       {
                         this.error = 'This email is already subscribed.';
                       }
                       else
                       {
                         this.error = body.title;
                       }
                     }

                   },
                   error => {
                     console.log(error);
                     this.loading = false;
                   }
                 );
    }
    else
    {
      this.signupErrors();
    }
  }

  signupErrors() {
    const {
      email
    } = this.form.controls;

    if (!email.value) {
      email.setErrors({
                        noValue: true
                      });
    }
  }

}
