import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import {HeaderComponent} from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { NewsletterSignupComponent } from './newsletter-signup/newsletter-signup.component';

import {PopupComponent} from './popup/popup.component';

import { ModalModule  } from 'ngx-bootstrap/modal';
import {ModalService} from './modal.service';

import {ShareButtonsModule} from 'ngx-sharebuttons';
import { BasicMessagePopupComponent } from './popup/basic-message-popup/basic-message-popup.component';
import { AcceptTermsPopupComponent } from './popup/accept-terms-popup/accept-terms-popup.component';
import { TermsComponent } from './terms/terms.component';
import {ValidationService} from './validation.service';
import { ControlMessagesComponent } from './control-messages/control-messages.component';

import {FacebookModule, FacebookService} from 'ngx-facebook';
import {TooltipModule} from 'ngx-bootstrap';

import { SafePipe } from './safe.pipe';
import { CloseMenuDirective } from './header/close-menu.directive';
import { SiteNoticeComponent } from './site-notice/site-notice.component';
import {CommonModule} from '@angular/common';
import { ShareButtonsContainerComponent } from './share-buttons-container/share-buttons-container.component';
import { NameGeneratorComponent } from './name-generator/name-generator.component';
import { NameSymbolComponent } from './name-generator/name-symbol/name-symbol.component';
import {NgxPageScrollCoreModule, PageScrollService} from 'ngx-page-scroll-core';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {ScrollSpyModule, ScrollSpyService} from 'ngx-scrollspy';
import {ClipboardModule} from 'ngx-clipboard';
import { MerchComponent } from './merch/merch.component';

const appRoutes: Routes = [
  { path: '', component:  HomeComponent },
  { path: 'terms', component:  TermsComponent },
  { path: 'legal', component:  SiteNoticeComponent },
  { path: 'shop', component:  HomeComponent, data: {fragment: 'shop'} },
  { path: 'namegenerator', component:  NameGeneratorComponent },
  { path: '**', redirectTo: '/'}
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    NewsletterSignupComponent,
    PopupComponent,
    BasicMessagePopupComponent,
    AcceptTermsPopupComponent,
    TermsComponent,
    ControlMessagesComponent,
    SafePipe,
    CloseMenuDirective,
    SiteNoticeComponent,
    ShareButtonsContainerComponent,
    NameGeneratorComponent,
    NameSymbolComponent,
    MerchComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    ShareButtonsModule.forRoot(),
    NgxPageScrollCoreModule.forRoot(),
    ScrollSpyModule.forRoot(),
    FacebookModule.forRoot(),
    ClipboardModule,
    TooltipModule.forRoot(),
    CommonModule,
  ],
  providers: [
    ModalService,
    PageScrollService,
    ValidationService,
    ScrollSpyService,
    FacebookService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
