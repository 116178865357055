import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "../validation.service";
import {Http, Headers} from "@angular/http";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-newsletter-signup',
  templateUrl: './newsletter-signup.component.html',
  styleUrls: ['./newsletter-signup.component.scss']
})
export class NewsletterSignupComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  error: string;
  success: string;

  constructor(private formBuilder: FormBuilder, private http: Http) {
    this.form = this.formBuilder.group({
                                         email: ['', [Validators.required, ValidationService.emailValidator]],
                                         firstName: [''],
                                         lastName: ['']
                                       });
  }

  ngOnInit() {
  }

  onSignUp() {
    if(this.form.valid)
    {
      this.loading = true;

      let postData = {
        email: this.form.value['email'],
        firstName: this.form.value['firstName'],
        lastName: this.form.value['lastName']
      };

      let headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});

      return this.http.post(environment.apiUrl + 'newsletter/', postData, {headers: headers})
                 .map(res => res.json())
                 .subscribe(
                   data => {
                     this.loading = false;
                     console.log(data);
                     let body = JSON.parse(data.content.body);

                     if(body.status == "subscribed")
                     {
                       this.form.reset();
                       this.success = data.content.updated ? 'You are already subscribed to our newsletter. We have updated your profile information.' : 'You have successfully subscribed to the Lost Ember Newsletter!';
                       this.error = '';
                     }
                     else
                     {
                       this.success = '';

                       if(body.title == "Member Exists")
                       {
                         this.error = "This email is already subscribed.";
                       }
                       else
                       {
                         this.error = body.title;
                       }
                     }

                   },
                   error => {
                     console.log(error);
                     this.loading = false;
                   }
                 );
    }
    else
    {
      this.signupErrors();
    }
  }

  signupErrors() {
    const {
      email
    } = this.form.controls;

    if (!email.value) {
      email.setErrors({
                        noValue: true
                      });
    }
  }

}
