import { Injectable } from '@angular/core';

@Injectable()
export class ModalService {
  activeModal: string = '';
  hideModalTimer;
  modalData: any = {};

  constructor() { }

  showModal(id: string, data?: any) {
    this.activeModal = id;
    this.modalData = data;
    console.log(data);
    clearTimeout(this.hideModalTimer);
  }

  hideModal()
  {
    this.activeModal = '';
  }

  isModalActive(id: string): boolean {
    return this.activeModal == id;
  }

}
